import React, { useState } from 'react'
import { TrackData } from '@interco/track'

import Layout from 'src/components/Layout'


// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Hero } from './sections/hero/_index'
import { CentralRelacionamento } from './sections/centralRelacionamento/_index'
import { ReadAllAboutInter } from './sections/read-all-about-inter/_index'
import pageContext from './pageContext.json'

import { Playlist } from './sections/playlist/_index'
import { View } from 'src/types/View'

import videos from 'src/assets/data/faq-babi/videos.json'


const IndexPage = () => {
  const [view, setView] = useState(View.Landing)
  const [submitedSearch, setSubmitedSearch] = useState('')
  const compactView = view === View.Search || view === View.Topic || view === View.ArticleByCategory
  const hasHeaderTitle = view === View.Search || view === View.Topic || view === View.Landing

  const [sendDatalayerEvent] = useDataLayer()

  const selectTopic = () => setView(View.Topic)

  TrackData.initOrUpdate({
    runtimeEnv: process.env.NODE_ENV,
    projectName: 'ajuda_inter_co',
  })

  const handleShowMoreArticle = () => setView(View.ArticleByCategory)

  return (
    <Layout pageContext={pageContext}>
      <Hero
        isHomePage
        hasBackground
        compactView={compactView}
        submitedSearch={submitedSearch}
        view={view}
        hasHeaderTitle={hasHeaderTitle}
        showBabiImage
      />
      <ReadAllAboutInter setSelectedTopic={selectTopic} sendDatalayerEvent={sendDatalayerEvent} setSelectedCategory={handleShowMoreArticle} />
      {videos.length > 0 && <Playlist />}
      <CentralRelacionamento sendDatalayerEvent={sendDatalayerEvent} />
    </Layout>
  )
}

export default IndexPage
